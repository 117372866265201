.cpcontainer {
   width: 100%;
   background-image: url("../../assets/images/chooseplayer.png");
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   background-color: #1a1a1a;
//    height: 35rem;

   &-wrapper {
        padding-top: 2rem;
        max-width: $fixedwidth;
        margin: 0px auto;
       
       
        &-content {
            width: 100%;
            @include flexbox();
            @include flex-direction(column);
            position: relative;
            h1 {
                @include heading(normal, 900, 50px, 73px,$font, #ffffff);
                text-align: center;
                text-transform: capitalize;
                position: absolute;
                top: 50px;
                right: 0;
                margin: 0px auto;
                left: 0;
              }


              img {
                width: 100%;
                z-index: 3;
                // position: absolute;
                // top: -45px;
                // height: 36rem;
              }
        }

     
   }

}

@media only screen and (max-width: 768px) and (min-width: 428px) { 
   .cpcontainer {
      &-wrapper {
         &-content {
            h1 {
               font-size: 34px;
               top: 18px;
            }
         }
      }
   }
}
@media only screen and (max-width: 428px) and (min-width: 375px) { 
   .cpcontainer {
      &-wrapper {
         &-content {
            h1 {
               font-size: 22px;
               top: -8px;
            }
         }
      }
   }
}
@media only screen and (max-width: 375px) and (min-width: 320px) { 
   .cpcontainer {
      &-wrapper {
         &-content {
            h1 {
               font-size: 20px;
               top: -10px;
            }
         }
      }
   }
}
@media only screen and (max-width: 320px) and (min-width: 280px) { 
   .cpcontainer {
      &-wrapper {
         &-content {
            h1 {
               font-size: 15px;
               top: -14px;
            }
         }
      }
   }
}