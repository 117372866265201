*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
  // background-color: #1a1a1a;
}

$fixedwidth: 1200px;
$font: DrukWideWeb;
$fontTwo: Poppins;
$colorOne: #f7941e;
$colorTwo: #ffffff;
// $colorbg: #131313;
// $colorbg-sec:#000000;
// $primary-textcolor: #FFFFFF;
// $secondary-textcolor:#C03540;
// $normal:400;
// $medium : 500;
// $semi-bold:600;
// $bold: 700;

@font-face {
  font-family: "DrukWideWeb";
  src: url("../assets/fonts/DrukWideWeb.ttf");
}
@font-face {
  font-family: "Poppins";
  src: url("../assets/fonts/Poppins.ttf");
}
