.missioncontianer {
  // height: 88rem;
  padding-bottom: 3.9%;
  width: 100%;
  background: white;
  background-image: url("../../assets/images/halfword.png");
  background-position: -4% 100%;
  background-repeat: no-repeat;
  background-size: auto;

  &-wrapper {
    max-width: $fixedwidth;
    margin: 0px auto;
    @include flexbox();
    @include flex-direction(column);

    &-missiondiv {
      padding-top: 4rem;
      @include flexbox();
      gap: 10px;

      &-leftside {
        width: 60%;
        @include flexbox();
        @include flex-direction(column);
        //   gap:10px;

        h3 {
          @include heading(normal, 900, 28px, 58px, $font, #f7941e);
          letter-spacing: 0.08em;
          text-transform: capitalize;
        }

        h1 {
          @include heading(normal, 900, 48px, 63px, $font, #1a1a1a);
          text-transform: uppercase;
        }

        p:nth-of-type(1) {
          @include heading(normal, 300, 16px, 28px, $fontTwo, #1a1a1a);
          width: 95%;
        }
        p:nth-of-type(2) {
          padding-top: 5px;
          width: 95%;
          @include heading(normal, 300, 16px, 28px, $fontTwo, #1a1a1a);
        }
      }

      &-rightside {
        width: 40%;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);

        img {
          width: 100%;
          padding-top: 3rem;
        }
      }
    }

    &-utilitiesdiv {
      padding-top: 8rem;
      @include flexbox();
      gap: 10px;

      &-imgdiv {
        width: 35%;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);

        img {
          width: 72%;
          margin-right: 50px;
        }
      }

      &-content {
        width: 65%;
        @include flexbox();
        @include flex-direction(column);
        gap: 10px;

        h1 {
          @include heading(normal, 900, 30px, 33px, $font, #1a1a1a);
          text-transform: capitalize;
        }

        &-data {
          @include flexbox();
          @include align-items(center);
          gap: 8px;

          span {
            @include heading(normal, 400, 16px, 31px, $fontTwo, #1a1a1a);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 2560px) and (min-width: 1980px) { 
  .missioncontianer {
        background-image: none;
   }
}
@media only screen and (max-width: 1440px) and (min-width: 1024px) { 
  .missioncontianer {
    background-position: -4% 100%;
    background-repeat: no-repeat;
    background-size: 27%;


        &-wrapper {

          &-utilitiesdiv {
           
    
            &-imgdiv {
            
    
              img {
                width: 75%;
                margin-right: 0px;
                background: white;
                border-radius: 160px;
              }
            }
    
            
          }
        }
   }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .missioncontianer {
    background-position: -118% 100%;
    background-repeat: no-repeat;
    background-size: 66%;

    &-wrapper {
      padding: 15px;

      &-missiondiv {
        &-leftside {
          h3 {
            font-size: 25px;
          }

          h1 {
            font-size: 38px;
          }

          p {
           
          }

          p:nth-of-type(1) {
            font-size: 15px;
            width: 100%;
          }
          p:nth-of-type(2) {
            padding-top: 5px;
            width: 100%;
            font-size: 15px;
          }

          
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) and (min-width: 428px) {
  .missioncontianer {

    background-position: -26% 100%;
    background-repeat: no-repeat;
    background-size: 66%;

    &-wrapper {
      padding: 15px;

      &-missiondiv {
        flex-direction: column;

        &-leftside {
          width: 100%;
          h3 {
            font-size: 25px;
          }

          h1 {
            font-size: 33px;
          }

          p:nth-of-type(1) {
            font-size: 14px;
            width: 100%;
          }
          p:nth-of-type(2) {
            padding-top: 5px;
            width: 100%;
            font-size: 14px;
          }
        }

        &-rightside {
          width: 100%;

          img {
            width: 53%;
            padding-top: 0px;
          }
        }
      }

      &-utilitiesdiv {
        flex-direction: column-reverse;

        &-imgdiv {
          width: 100%;

          img {
            width: 30%;
            margin-right: 0px;
            background: white;
            border-radius: 160px;
          }
        }

        &-content {
          width: 100%;
        }
      }
    }
  }
}
@media only screen and (max-width: 428px) and (min-width: 375px) {
  .missioncontianer {
    background-position: -26% 100%;
    background-repeat: no-repeat;
    background-size: 66%;
    &-wrapper {
      padding: 15px;

      &-missiondiv {
        flex-direction: column;

        &-leftside {
          width: 100%;
          h3 {
            font-size: 19px;
          }

          h1 {
            font-size: 23px;
          }

          p:nth-of-type(1) {
            font-size: 14px;
            width: 100%;
          }
          p:nth-of-type(2) {
            padding-top: 5px;
            width: 100%;
            font-size: 14px;
          }
        }

        &-rightside {
          width: 100%;

          img {
            width: 70%;
            padding-top: 0px;
          }
        }
      }

      &-utilitiesdiv {
        flex-direction: column-reverse;

        &-imgdiv {
          width: 100%;

          img {
            width: 50%;
            margin-right: 0px;
            background: white;
            border-radius: 160px;
          }
        }

        &-content {
          width: 100%;

          &-data {
            span {
              font-size: 14px;
              line-height: 17px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 375px) and (min-width: 320px) {
  .missioncontianer {
    background-position: -26% 100%;
    background-repeat: no-repeat;
    background-size: 66%;
    &-wrapper {
      padding: 15px;

      &-missiondiv {
        flex-direction: column;

        &-leftside {
          width: 100%;
          h3 {
            font-size: 18px;
          }

          h1 {
            font-size: 19px;
            line-height: 40px;
          }

          p:nth-of-type(1) {
            font-size: 14px;
            width: 100%;
          }
          p:nth-of-type(2) {
            padding-top: 5px;
            width: 100%;
            font-size: 14px;
          }
        }

        &-rightside {
          width: 100%;

          img {
            width: 80%;
            padding-top: 0px;
          }
        }
      }

      &-utilitiesdiv {
        flex-direction: column-reverse;

        &-imgdiv {
          width: 100%;

          img {
            width: 55%;
            margin-right: 0px;
            background: white;
            border-radius: 160px;
          }
        }

        &-content {
          width: 100%;

          h1 {
            font-size: 20px;
          }

          &-data {
            span {
              font-size: 12px;
              line-height: 15px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 280px) and (min-width: 200px) {
  .missioncontianer {
    background-position: -26% 100%;
    background-repeat: no-repeat;
    background-size: 66%;
    &-wrapper {
      padding: 15px;

      &-missiondiv {
        flex-direction: column;

        &-leftside {
          width: 100%;
          h3 {
            font-size: 18px;
          }

          h1 {
            font-size: 17px;
            line-height: 40px;
          }

          p:nth-of-type(1) {
            font-size: 11px;
            width: 100%;
          }
          p:nth-of-type(2) {
            padding-top: 5px;
            width: 100%;
            font-size: 11px;
          }
        }

        &-rightside {
          width: 100%;

          img {
            width: 85%;
            padding-top: 0px;
          }
        }
      }

      &-utilitiesdiv {
        flex-direction: column-reverse;

        &-imgdiv {
          width: 100%;

          img {
            width: 55%;
            margin-right: 0px;
            background: white;
            border-radius: 160px;
          }
        }

        &-content {
          width: 100%;

          h1 {
            font-size: 18px;
          }

          &-data {
            span {
              font-size: 12px;
              line-height: 15px;
            }
          }
        }
      }
    }
  }
}
