.btncontainer {
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  border: 2px solid #f7941e;
  border-radius: 194.04px;
  margin: 0px auto;
  padding: 18px;
  cursor: pointer;

  h5 {
    @include heading(normal, 900, 20px, 22px, $font, $colorTwo);
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: capitalize;
  }
}
