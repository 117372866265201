.ourteamcontainer {
  width: 100%;
  background-image: url("../../assets/images/ourtemabg.png");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 380px;
  background-color: #1a1a1a;

  &-wrapper {
    padding-top: 4rem;
    max-width: $fixedwidth;
    margin: 0px auto;

    &-btndiv {
      margin: 0px auto;
      width: 20%;
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
    }

    &-teamdiv {
      padding-top: 8rem;
      width: 100%;
      @include flexbox();
      @include flex-direction(column);

      &-parentdiv {
        width: 100%;
        @include flexbox();
        @include align-items(center);
        gap: 30px;

        p {
          width: 60%;
          @include heading(normal, 300, 16px, 28px, $fontTwo, #ffffff);
          text-transform: capitalize;
        }

        &-heading {
          width: 30%;
          @include flexbox();
          @include flex-direction(column);
          border-right: 1px solid #ffffff;

          h3 {
            @include headingOutline(normal, 900, 28px, 58px, $font, #f7941e);
            letter-spacing: 0.08em;
            text-transform: capitalize;
          }

          h1 {
            @include heading(normal, 900, 50px, 63px, $font, #ffffff);
            text-transform: uppercase;
          }
        }
      }
    }

    &-teamsliderdiv {
      padding-top: 2rem;
      width: 100%;
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-between);

      &-imgLeft {
        width: 5%;
      }

      &-imgRight {
        width: 5%;
      }

      &-parent {
        width: 90%;

        &-siderOne {
          width: 93%;
          margin: 0px auto;
          @include flexbox();
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 8px;

          img {
            width: 100%;
          }

          .teamsliderimg {
            height: 280px;
            width: 235px;
            // border-radius: 98px;
            // border:5px solid #f7941e;
          }

          h1 {
            padding-top: 10px;
            @include heading(normal, 900, 22px, 22px, $font, #ffffff);
            text-align: center;
            letter-spacing: 0.02em;
            text-transform: capitalize;
          }

          h2 {
            @include heading(normal, 400, 16px, 28px, $fontTwo, #f7941e);
            text-align: center;
            text-transform: capitalize;
          }

          &-icons {
            display: flex;
            gap: 8px;

            img {
              width: 50%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .ourteamcontainer {
    &-wrapper {
      padding: 4rem 15px 15px 15px;

      &-teamsliderdiv {
        &-imgLeft {
          width: 5%;
        }

        &-imgRight {
          width: 5%;
        }

        &-parent {
          &-siderOne {
            width: 58%;

            .teamsliderimg {
              height: 310px;
              width: 280px;
              // border-radius: 98px;
              // border:5px solid #f7941e;
          
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) and (min-width: 428px) {
  .ourteamcontainer {
    background-size: 300px;

    &-wrapper {
      padding: 4rem 15px 15px 15px;

      &-btndiv {
        width: 28%;

        .btncontainer {
          padding: 15px;

          h5 {
            font-size: 18px;
          }
        }
      }

      &-teamdiv {
        &-parentdiv {
          p {
            font-size: 15px;
          }

          &-heading {
            h3 {
              font-size: 24px;
            }

            h1 {
              font-size: 40px;
            }
          }
        }
      }

      &-teamsliderdiv {
        &-imgLeft {
          width: 5%;
        }

        &-imgRight {
          width: 5%;
        }

        &-parent {
          &-siderOne {
            width: 75%;
            gap:5px;

            .teamsliderimg {
              height: 280px;
              width: 250px;
              // border-radius: 98px;
              // border:5px solid #f7941e;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 428px) and (min-width: 320px) {
  .ourteamcontainer {
    background-size: 300px;

    &-wrapper {
      padding: 4rem 15px 15px 15px;

      &-btndiv {
        width: 50%;

        .btncontainer {
          padding: 15px;

          h5 {
            font-size: 18px;
          }
        }
      }

      &-teamdiv {
        &-parentdiv {
          flex-direction: column;
          gap: 15px;

          p {
            font-size: 15px;
            width: 100%;
          }

          &-heading {
            width: 100%;
            border-right: none;
            align-items: center;
            justify-content: center;

            h3 {
              font-size: 20px;
              line-height: 30px;
            }

            h1 {
              font-size: 30px;
            }
          }
        }
      }

      &-teamsliderdiv {
        &-imgLeft {
          width: 10%;
        }

        &-imgRight {
          width: 10%;
        }

        &-parent {
          width: 80%;

          &-siderOne {
            width: 85%;
            gap:5px;

            .teamsliderimg {
              height: 290px;
              width: 250px;
              // border-radius: 98px;
              // border:5px solid #f7941e;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 320px) and (min-width: 280px) {
  .ourteamcontainer {
    background-size: 300px;

    &-wrapper {
      padding: 4rem 15px 15px 15px;

      &-btndiv {
        width: 70%;

        .btncontainer {
          padding: 15px;

          h5 {
            font-size: 18px;
          }
        }
      }

      &-teamdiv {
        &-parentdiv {
          flex-direction: column;
          gap: 15px;

          p {
            font-size: 15px;
            width: 100%;
          }

          &-heading {
            width: 100%;
            border-right: none;
            align-items: center;
            justify-content: center;

            h3 {
              font-size: 20px;
              line-height: 30px;
            }

            h1 {
              font-size: 30px;
            }
          }
        }
      }

      &-teamsliderdiv {
        &-imgLeft {
          width: 10%;
        }

        &-imgRight {
          width: 10%;
        }

        &-parent {
          width: 80%;

          &-siderOne {
            width: 90%;

            .teamsliderimg {
              height: 240px;
              width: 220px;
              // border-radius: 98px;
              // border:5px solid #f7941e;
            }

            h1 {
              font-size: 21px;
            }
          }
        }
      }
    }
  }
}