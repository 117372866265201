.ppcont {
    width: 100%;
    height: 100%;
    background-color: white;

    &-wrapper {
        max-width: $fixedwidth;
        margin: 0px auto;
        display: flex;
        flex-direction: column;
        padding-top: 3rem;
        padding-bottom: 5rem;

        &-topheading {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            h1 {
                @include heading(normal, 800, 25px, 58px, $fontTwo, black);
            }
        }

        &-contentparent {
            width: 100%;
            display: flex;
            flex-direction: column;

            &-headingdiv {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                h2 {
                    @include heading(normal, 700, 21px, 40px, $fontTwo, black);
                }
            }

            &-contentdiv {
                width: 100%;
                display: flex;
                gap: 20px;
                flex-direction: column;

                &-mapdata {
                    display: flex;
                    gap: 5px;

                    span {
                        @include heading(normal, 700, 18px, 20px, $fontTwo, black);
                    }
                    p {
                        @include heading(normal, 700, 18px, 20px, $fontTwo, black);
                    }
                    h6 {
                        @include heading(normal, 700, 18px, 20px, $fontTwo, black);
                        font-style: italic;
                    }
                }
            }
        }

        &-cdata {
            display: flex;
            flex-direction: column;
            gap: 10px;

            &-heading {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                h5 {
                    @include heading(normal, 700, 21px, 40px, $fontTwo, black);
                }
            }

            &-headingdata {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 5px;

                h4 {
                    @include heading(normal, 700, 20px, 40px, $fontTwo, black);
                }

                p:nth-of-type(1) {
                    @include heading(normal, 500, 18px, 20px, $fontTwo, black);
                }
                p:nth-of-type(2) {
                    @include heading(normal, 500, 18px, 20px, $fontTwo, black);
                    padding-top: 12px;
                }
                p:nth-of-type(3) {
                    @include heading(normal, 500, 18px, 20px, $fontTwo, black);
                    padding-top: 12px;
                }
                p:nth-of-type(4) {
                    @include heading(normal, 500, 18px, 20px, $fontTwo, black);
                    padding-top: 12px;
                }

                &-centerheading {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 10px;

                    h3 {
                        @include heading(normal, 500, 18px, 20px, $fontTwo, black);
                    }
                }

                &-bulletpoints {
                    display: flex;
                    gap: 5px;
                    padding-top: 10px;

                    h6 {
                        @include heading(normal, 500, 18px, 20px, $fontTwo, black);
                    }
                }
            }
        }
    }
}
