.roadmapcontainer {
  width: 100%;
  background-image: url("../../assets/images/roadmapbg.png"), url("../../assets/images/roadmapimgtwo.png");
  background-position: center, left 10%;
  background-repeat: no-repeat, no-repeat;
  background-size: cover, 220px;
  background-color: #1a1a1a;

  &-wrapper {
    max-width: $fixedwidth;
    margin: 0px auto;
    @include flexbox();
    @include flex-direction(column);

    &-heading {
      width: 100%;
      padding-top: 8rem;
      @include flexbox();
      @include flex-direction(column);
      @include align-items(center);
      @include justify-content(center);

      h3 {
        @include headingOutline(normal, 900, 28px, 58px, $font, #f7941e);
        letter-spacing: 0.08em;
        text-transform: capitalize;
      }

      h1 {
        @include heading(normal, 900, 50px, 63px, $font, #ffffff);
        text-transform: uppercase;
      }

      p {
        width: 90%;
        @include heading(normal, 300, 16px, 28px, $fontTwo, #ffffff);
        text-align: center;
        text-transform: capitalize;
      }
    }

    &-roadmapdiv {
      padding-top: 8rem;
      padding-bottom: 5rem;
      width: 100%;
      display: flex;
      //   height: 30rem;

      &-leftside {
        width: 45%;
        @include flexbox();
        @include flex-direction(column);

        &-nodeone {
          @include flexbox();
          @include flex-direction(column);
          gap: 5px;

          h2 {
            @include heading(normal, 900, 28px, 28px, $font, #ffffff);
            letter-spacing: 0.08em;
            text-transform: capitalize;
          }

          p {
            @include heading(normal, 300, 16px, 28px, $fontTwo, #ffffff);
            text-transform: capitalize;
          }
        }
        &-nodetwo {
          padding-top: 14.5rem;
          @include flexbox();
          @include flex-direction(column);
          gap: 5px;

          h2 {
            @include heading(normal, 900, 28px, 28px, $font, #ffffff);
            letter-spacing: 0.08em;
            text-transform: capitalize;
          }

          p {
            @include heading(normal, 300, 16px, 28px, $fontTwo, #ffffff);
            text-transform: capitalize;
            width: 80%;
          }
        }
        &-nodethree {
          padding-top: 11.5rem;
          @include flexbox();
          @include flex-direction(column);
          gap: 5px;

          h2 {
            @include heading(normal, 900, 28px, 28px, $font, #ffffff);
            letter-spacing: 0.08em;
            text-transform: capitalize;
          }

          p {
            @include heading(normal, 300, 16px, 28px, $fontTwo, #ffffff);
            text-transform: capitalize;
            width: 80%;
          }
        }
      }

      &-imgdiv {
        @include flexbox();
        height: 50rem;
        width: 6%;
      }

      &-rightside {
        padding-top: 9rem;
        width: 47%;
        @include flexbox();
        @include flex-direction(column);

        &-nodeone {
          @include flexbox();
          @include flex-direction(column);
          @include justify-content(flex-end);
          @include align-items(flex-end);

          gap: 5px;

          h2 {
            @include heading(normal, 900, 28px, 28px, $font, #ffffff);
            letter-spacing: 0.08em;
            text-transform: capitalize;
          }

          p {
            @include heading(normal, 300, 16px, 28px, $fontTwo, #ffffff);
            text-transform: capitalize;
            text-align: right;
            width: 60%;
          }
        }
        &-nodetwo {
          padding-top: 12.5rem;
          @include flexbox();
          @include flex-direction(column);
          @include justify-content(flex-end);
          @include align-items(flex-end);
          gap: 5px;

          h2 {
            @include heading(normal, 900, 28px, 28px, $font, #ffffff);
            letter-spacing: 0.08em;
            text-transform: capitalize;
          }

          p {
            @include heading(normal, 300, 16px, 28px, $fontTwo, #ffffff);
            text-transform: capitalize;
            text-align: right;
            width: 60%;
          }
        }
        &-nodethree {
          padding-top: 12rem;
          @include flexbox();
          @include flex-direction(column);
          @include justify-content(flex-end);
          @include align-items(flex-end);
          gap: 5px;

          h2 {
            @include heading(normal, 900, 28px, 28px, $font, #ffffff);
            letter-spacing: 0.08em;
            text-transform: capitalize;
          }

          p {
            @include heading(normal, 300, 16px, 28px, $fontTwo, #ffffff);
            text-transform: capitalize;
            text-align: right;
            width: 60%;
          }
        }
      }
    }

    &-benefits {
      padding-top: 5rem;
      width: 100%;
      @include flexbox();
      background-image: url("../../assets/images/rooney1.png");
      background-repeat: no-repeat;
      background-position: 110% 95%;
      background-size: 40%;

      &-content {
        width: 70%;
        @include flexbox();
        @include flex-direction(column);
        gap: 10px;
        padding-bottom: 4rem;

        h1 {
          @include heading(normal, 900, 30px, 39px, $font, #ffffff);
          text-transform: capitalize;
        }

        &-data {
          @include flexbox();
          //   align-items: center;
          gap: 6px;

          span {
            width: 2%;
            @include heading(normal, 300, 16px, 28px, $fontTwo, #f7941e);
            text-transform: capitalize;
          }
          p {
            @include heading(normal, 300, 16px, 28px, $fontTwo, #ffffff);
            text-transform: capitalize;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .roadmapcontainer {
    &-wrapper {
      padding: 15px 15px 0px 15px;

      &-heading {
        h3 {
          font-size: 25px;
        }

        h1 {
          font-size: 38px;
        }
      }

      &-roadmapdiv {
        &-imgdiv {
          width: 7%;
        }

        &-rightside {
          &-nodethree {
            padding-top: 10rem;
          }
        }
      }

      &-benefits {
        background-position: 112% 100%;
        background-size: 45%;
      }
    }
  }
}
@media only screen and (max-width: 786px) and (min-width: 428px) {
  .roadmapcontainer {
    &-wrapper {
      padding: 15px 15px 0px 15px;

      &-heading {
        h3 {
          font-size: 25px;
        }

        h1 {
          font-size: 38px;
        }
      }

      &-roadmapdiv {
        &-imgdiv {
          width: 10%;
        }

        &-rightside {
          width: 45%;

          &-nodetwo {
            padding-top: 10.5rem;
          }

          &-nodethree {
            padding-top: 8.5rem;
          }
        }

        &-leftside {
          width: 45%;

          &-nodethree {
            padding-top: 10rem;
          }
        }
      }

      &-benefits {
        background-position: 112% 100%;
        background-size: 50%;
      }
    }
  }
}
@media only screen and (max-width: 428px) and (min-width: 375px) {
  .roadmapcontainer {
    &-wrapper {
      padding: 15px 15px 0px 15px;

      &-heading {
        h3 {
          font-size: 25px;
        }

        h1 {
          font-size: 38px;
        }
      }

      &-roadmapdiv {
        &-imgdiv {
          width: 20%;

          img {
            width: 100%;
          }
        }

        &-rightside {
          width: 40%;

          &-nodeone {
            h2 {
              font-size: 22px;
            }

            p {
              font-size: 11px;
              width: 100%;
            }
          }

          &-nodetwo {
            padding-top: 11rem;

            h2 {
              font-size: 22px;
            }

            p {
              font-size: 11px;
              width: 100%;
            }
          }

          &-nodethree {
            padding-top: 9.5rem;

            h2 {
              font-size: 22px;
            }

            p {
              font-size: 11px;
              width: 100%;
            }
          }
        }

        &-leftside {
          width: 40%;

          &-nodeone {
            h2 {
              font-size: 22px;
            }

            p {
              font-size: 11px;
              width: 90%;
            }
          }

          &-nodetwo {
            padding-top: 12.5rem;

            h2 {
              font-size: 22px;
            }

            p {
              font-size: 11px;
              width: 100%;
            }
          }

          &-nodethree {
            padding-top: 8rem;

            h2 {
              font-size: 22px;
            }

            p {
              font-size: 11px;
              width: 100%;
            }
          }
        }
      }

      &-benefits {
        background-position: bottom, center;
        background-size: 100%;

        &-content {
          width: 100%;

          h1 {
            font-size: 21px;
          }

          &-data {
            span {
              font-size: 15px;
            }

            p {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 375px) and (min-width: 320px) {
  .roadmapcontainer {
    &-wrapper {
      padding: 15px 15px 0px 15px;

      &-heading {
        h3 {
          font-size: 25px;
        }

        h1 {
          font-size: 38px;
        }
      }

      &-roadmapdiv {
        &-imgdiv {
          width: 21%;

          img {
            width: 100%;
          }
        }

        &-rightside {
          width: 40.5%;

          &-nodeone {
            h2 {
              font-size: 19px;
            }

            p {
              font-size: 10px;
              width: 100%;
            }
          }

          &-nodetwo {
            padding-top: 11rem;

            h2 {
              font-size: 19px;
            }

            p {
              font-size: 10px;
              width: 100%;
            }
          }

          &-nodethree {
            padding-top: 9.5rem;

            h2 {
              font-size: 19px;
            }

            p {
              font-size: 10px;
              width: 100%;
            }
          }
        }

        &-leftside {
          width: 40.5%;

          &-nodeone {
            h2 {
              font-size: 19px;
            }

            p {
              font-size: 10px;
              width: 90%;
            }
          }

          &-nodetwo {
            padding-top: 12.5rem;

            h2 {
              font-size: 19px;
            }

            p {
              font-size: 10px;
              width: 100%;
            }
          }

          &-nodethree {
            padding-top: 8rem;

            h2 {
              font-size: 19px;
            }

            p {
              font-size: 10px;
              width: 100%;
            }
          }
        }
      }

      &-benefits {
        background-position: bottom, center;
        background-size: 100%;

        &-content {
          width: 100%;

          h1 {
            font-size: 21px;
          }

          &-data {
            span {
              font-size: 15px;
            }

            p {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 320px) and (min-width: 280px) {
  .roadmapcontainer {
    &-wrapper {
      padding: 10px 10px 0px 10px;

      &-heading {
        h3 {
          font-size: 25px;
        }

        h1 {
          font-size: 38px;
        }
      }

      &-roadmapdiv {
        &-imgdiv {
          width: 22%;

          img {
            width: 100%;
          }
        }

        &-rightside {
          width: 39%;

          &-nodeone {
            h2 {
              font-size: 16px;
            }

            p {
              font-size: 10px;
              width: 100%;
            }
          }

          &-nodetwo {
            padding-top: 11rem;

            h2 {
              font-size: 16px;
            }

            p {
              font-size: 10px;
              width: 100%;
            }
          }

          &-nodethree {
            padding-top: 8rem;

            h2 {
              font-size: 19px;
            }

            p {
              font-size: 10px;
              width: 100%;
            }
          }
        }

        &-leftside {
          width: 39%;

          &-nodeone {
            h2 {
              font-size: 16px;
            }

            p {
              font-size: 10px;
              width: 90%;
            }
          }

          &-nodetwo {
            padding-top: 12.5rem;

            h2 {
              font-size: 16px;
            }

            p {
              font-size: 10px;
              width: 100%;
            }
          }

          &-nodethree {
            padding-top: 7rem;

            h2 {
              font-size: 16px;
            }

            p {
              font-size: 10px;
              width: 100%;
            }
          }
        }
      }

      &-benefits {
        background-position: bottom, center;
        background-size: 100%;

        &-content {
          width: 100%;

          h1 {
            font-size: 21px;
          }

          &-data {
            span {
              font-size: 15px;
            }

            p {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
