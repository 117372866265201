.accordian-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.05);
    opacity: 0.8;
    border: 1px solid #FFFFFF;
    border-radius: 28px;
    padding: 20px;
    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border-bottom:  0.5px solid #FFFFFF;
        padding-bottom: 10px;
        opacity: 0.8;

        h1 {
            @include heading(normal, 400, 20px, 24px, $fontTwo, #ffffff);
            text-transform: capitalize;
        }
       
    }

    &-content {
        width: 100%;
        padding-top: 15px;
       
      

         p{
            @include heading(normal, 300, 16px, 24px, $fontTwo, #ffffff);
            text-transform: capitalize;
         }
    }
}