.headercontainer {
    padding-top: 1rem;
    width: 100%;
    @include flexbox();
    position: absolute;
    top: 0;
    z-index: 1;

    &-wrapper {
        width: 100%;
        @include flexbox();

        &-burgericon{
            width: 10%;
            @include flexbox();
            @include align-items(flex-start);
            @include justify-content(center);

            img {
                padding-top: 1rem;
                width: 20%;
            }
           
        }

        &-logodiv {
            width: 80%;
            @include flexbox();
            @include align-items(center);
            @include justify-content(center);

            img {
                width: 15%;
            }
        }

        &-emptydiv {
            width: 10%;
        }
    }
}

@media only screen and (max-width: 1024px) and (min-width: 768px) { 
    .headercontainer {
        &-wrapper {
            &-burgericon {
                img {
                    width: 32%;
                }
            }

            &-logodiv { 
                img {
                    width: 22%;
                }
            }
        }
    }
}
@media only screen and (max-width: 768px) and (min-width: 428px) { 
    .headercontainer {
        &-wrapper {
            &-burgericon {
                img {
                    width: 32%;
                }
            }

            &-logodiv { 
                img {
                    width: 22%;
                }
            }
        }
    }
}
@media only screen and (max-width: 428px) and (min-width: 375px) { 
    .headercontainer {
        &-wrapper {
            &-burgericon {
                img {
                    width: 50%;
                }
            }

            &-logodiv { 
                img {
                    width: 36%;
                }
            }
        }
    }
}
@media only screen and (max-width: 375px) and (min-width: 280px) { 
    .headercontainer {
        &-wrapper {
            &-burgericon {
                img {
                    width: 55%;
                }
            }

            &-logodiv { 
                img {
                    width: 40%;
                }
            }
        }
    }
}
@media only screen and (max-width: 280px) and (min-width: 200px) { 
    .headercontainer {
        &-wrapper {
            &-burgericon {
                img {
                    width: 55%;
                }
            }

            &-logodiv { 
                img {
                    width: 47%;
                }
            }
        }
    }
}