.ourtencontainer {
  height: 60rem;
  width: 100%;
  background-image: url("../../assets/images/halfworld.svg");
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: 250px;
  background-color: #1a1a1a;

  &-wrapper {
    max-width: $fixedwidth;
    margin: 0px auto;
    @include flexbox();
    @include flex-direction(column);

    &-headings {
      width: 100%;
      @include flexbox();
      @include flex-direction(column);
      @include align-items(center);

      h3 {
        @include headingOutline(normal, 900, 28px, 58px, $font, #f7941e);
      }

      h1 {
        @include heading(normal, 900, 60px, 73px, $font, #ffffff);
        text-transform: uppercase;
      }
    }

    &-sliderdiv {
      padding-top: 2rem;
      width: 100%;
      @include flexbox();
      @include align-items(center);
      @include justify-content(space-between);
    }
  }
}
.sliderdiv {
  width: 90%;
}

.slideone {
  width: 60%;
  @include flexbox();
  @include flex-direction(column);
  position: relative;
  margin: 0 auto;
  gap: 10px;

  h1 {
    @include heading(normal, 900, 11px, 26px, $font, #ffffff);
    text-transform: uppercase;
    text-align: center;
  }

  &-imgdiv {
    height: 330px;
    width: 210px;
    background: rgba(255, 255, 255, 0.1);
    border: 3px solid #ffffff;
    border-radius: 113px;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    &-imgOne {
      position: absolute;
      bottom: 36px;
      border-radius: 98px;
    }
    &-imgTwo {
      position: absolute;
      bottom: 36px;
      border-radius: 98px;
    }
    &-imgThree {
      position: absolute;
      bottom: 36px;
      border-radius: 98px;
    }
  }
}


li.react-multi-carousel-item.react-multi-carousel-item--active + li .slideone {
  transform: scale(1.5);
}


li.react-multi-carousel-item.react-multi-carousel-item--active .slideone {
  transform: scale(0.9);
}

li.react-multi-carousel-item.react-multi-carousel-item--active
  + li
  + li
  .slideone {
  transform: scale(0.9);
}

li.react-multi-carousel-item.react-multi-carousel-item--active
  + li
  .slideone-imgdiv {
  border: 4px solid #f7941e;
}
li.react-multi-carousel-item.react-multi-carousel-item--active
  + li
  + li
  .slideone-imgdiv {
  border-color: #fff;
}

ul.react-multi-carousel-track {
  min-height: 608px;
  align-items: center;
}




@media only screen and (max-width: 1024px) and (min-width: 768px) { 

  .ourtencontainer {
    

    &-wrapper {

      &-headings {

        h3 {
          font-size: 25px;
        }

        h1 {
          font-size: 38px;
        }

      }

      &-sliderdiv {
        padding: 2rem 15px 15px 15px;


      
        &-imgLeft {
          width: 5%;
        }

        

        &-imgRight {
          width: 5%;
        }
      
     
    }
    }
  

    
  }
  .slideone {
    width: 75%;
    @include flexbox();
    @include flex-direction(column);
    position: relative;
    margin: 0px auto;
    gap: 10px;
  
    h1 {
      @include heading(normal, 900, 11px, 26px, $font, #ffffff);
      text-transform: uppercase;
      text-align: center;
    }
  
    &-imgdiv {
      height: 325px;
  
      // &-imgTwo {
      //   position: absolute;
      // bottom: 60px;
      // border-radius: 94px;
      // }
     
     
    }
  }
 

 }
@media only screen and (max-width: 768px) and (min-width: 428px) { 

  .ourtencontainer {
    

    &-wrapper {

      &-headings {

        h3 {
          font-size: 25px;
        }

        h1 {
          font-size: 38px;
        }

      }

      &-sliderdiv {
        padding: 2rem 15px 15px 15px;


      
        &-imgLeft {
          width: 5%;
        }

        

        &-imgRight {
          width: 5%;
        }
      
     
    }
    }
  

    
  }
  .slideone {
    width: 75%;
    @include flexbox();
    @include flex-direction(column);
    position: relative;
    margin: 0px auto;
    gap: 10px;
  
    h1 {
      @include heading(normal, 900, 9px, 26px, $font, #ffffff);
      text-transform: uppercase;
      text-align: center;
    }
  
    &-imgdiv {
      height: 230px;
      width: 150px;

  
      &-imgOne {
        width: 80%;
        position: absolute;
        bottom: 33.2px;
        border-radius: 98px;
      }
      &-imgTwo {
        width: 80%;
        position: absolute;
        bottom: 33.2px;
        border-radius: 98px;
      }
      &-imgThree {
        width: 80%;
        position: absolute;
        bottom: 33.2px;
        border-radius: 98px;
      }
     
     
    }
  }
 

 }
@media only screen and (max-width: 428px) and (min-width: 375px) { 

  .ourtencontainer {
    background-size: 150px;
    height: 55rem;

    &-wrapper {

      &-headings {

        h3 {
          font-size: 17px;
        }

        h1 {
          font-size: 26px;
        }

      }

      &-sliderdiv {
        padding: 15px 15px 15px 15px;


      
        &-imgLeft {
          width: 10%;
        }

        

        &-imgRight {
          width: 10%;
        }
      
     
    }
    }
  

    
  }
  .sliderdiv {
    width: 80%;
  }
  .slideone {
    width: 75%;
    @include flexbox();
    @include flex-direction(column);
    position: relative;
    margin: 0px auto;
    gap: 10px;
    align-items: center;
    justify-content: center;
  
    h1 {
      @include heading(normal, 900, 10px, 26px, $font, #ffffff);
      text-transform: uppercase;
      text-align: center;
    }
  
    &-imgdiv {
      height: 245px;
      width: 160px;

  
      &-imgOne {
        width: 60%;
        position: absolute;
        bottom: 33.2px;
        border-radius: 98px;
      }
      &-imgTwo {
        width: 60%;
        position: absolute;
        bottom: 33.2px;
        border-radius: 98px;
      }
      &-imgThree {
        width: 60%;
        position: absolute;
        bottom: 33.2px;
        border-radius: 98px;
      }
     
     
    }
  }
  li.react-multi-carousel-item.react-multi-carousel-item--active  .slideone {
    transform: scale(1.5);
  }
  
  
  
  li.react-multi-carousel-item.react-multi-carousel-item--active
    
    .slideone-imgdiv {
    border: 4px solid #f7941e;
  }
  
  
  ul.react-multi-carousel-track {
    min-height: 550px;
    align-items: center;
  }

 }
@media only screen and (max-width: 375px) and (min-width: 320px) { 

  .ourtencontainer {
    background-size: 180px;
    height: 52rem;

    &-wrapper {

      &-headings {
        padding: 10px;

        h3 {
          font-size: 15px;
          line-height: 15px;
        }

        h1 {
          font-size: 23px;
          line-height: 58px;
        }

      }

      &-sliderdiv {
        padding: 10px;


      
        &-imgLeft {
          width: 10%;
        }

        

        &-imgRight {
          width: 10%;
        }
      
     
    }
    }
  

    
  }
  .sliderdiv {
    width: 80%;
  }
  .slideone {
    width: 75%;
    @include flexbox();
    @include flex-direction(column);
    position: relative;
    margin: 0px auto;
    gap: 10px;
    align-items: center;
    justify-content: center;
  
    h1 {
      @include heading(normal, 900, 8px, 26px, $font, #ffffff);
      text-transform: uppercase;
      text-align: center;
    }
  
    &-imgdiv {
      height: 220px;
      width: 150px;

  
      &-imgOne {
        width: 60%;
        position: absolute;
        bottom: 33.2px;
        border-radius: 98px;
      }
      &-imgTwo {
        width: 60%;
        position: absolute;
        bottom: 33.2px;
        border-radius: 98px;
      }
      &-imgThree {
        width: 60%;
        position: absolute;
        bottom: 33.2px;
        border-radius: 98px;
      }
     
     
    }
  }
  li.react-multi-carousel-item.react-multi-carousel-item--active  .slideone {
    transform: scale(1.5);
  }
  
  
  
  li.react-multi-carousel-item.react-multi-carousel-item--active
    
    .slideone-imgdiv {
    border: 4px solid #f7941e;
  }
  
  
  ul.react-multi-carousel-track {
    min-height: 450px;
    align-items: center;
  }

 }
@media only screen and (max-width: 320px) and (min-width: 280px) { 

  .ourtencontainer {
    background-size: 180px;
    height: 52rem;

    &-wrapper {

      &-headings {
        padding: 10px;

        h3 {
          font-size: 15px;
          line-height: 15px;
        }

        h1 {
          font-size: 19px;
          line-height: 58px;
        }

      }

      &-sliderdiv {
        padding: 10px;


      
        &-imgLeft {
          width: 10%;
        }

        

        &-imgRight {
          width: 10%;
        }
      
     
    }
    }
  

    
  }
  .sliderdiv {
    width: 80%;
  }
  .slideone {
    width: 75%;
    @include flexbox();
    @include flex-direction(column);
    position: relative;
    margin: 0px auto;
    gap: 10px;
    align-items: center;
    justify-content: center;
  
    h1 {
      @include heading(normal, 900, 8px, 26px, $font, #ffffff);
      text-transform: uppercase;
      text-align: center;
    }
  
    &-imgdiv {
      height: 200px;
      width: 130px;

  
      &-imgOne {
        width: 62%;
        position: absolute;
        bottom: 33.2px;
        border-radius: 98px;
      }
      &-imgTwo {
        width: 62%;
        position: absolute;
        bottom: 33.2px;
        border-radius: 98px;
      }
      &-imgThree {
        width: 62%;
        position: absolute;
        bottom: 33.2px;
        border-radius: 98px;
      }
     
     
    }
  }
  li.react-multi-carousel-item.react-multi-carousel-item--active  .slideone {
    transform: scale(1.5);
  }
  
  
  
  li.react-multi-carousel-item.react-multi-carousel-item--active
    
    .slideone-imgdiv {
    border: 4px solid #f7941e;
  }
  
  
  ul.react-multi-carousel-track {
    min-height: 450px;
    align-items: center;
  }

 }
@media only screen and (max-width: 280px) and (min-width: 200px) { 

  .ourtencontainer {
    background-size: 170px;
    height: 49rem;

    &-wrapper {

      &-headings {
        padding: 10px;

        h3 {
          font-size: 15px;
          line-height: 15px;
        }

        h1 {
          font-size: 17px;
    line-height: 60px;
        }

      }

      &-sliderdiv {
        padding: 10px;


      
        &-imgLeft {
          width: 10%;
        }

        

        &-imgRight {
          width: 10%;
        }
      
     
    }
    }
  

    
  }
  .sliderdiv {
    width: 80%;
  }
  .slideone {
    width: 75%;
    @include flexbox();
    @include flex-direction(column);
    position: relative;
    margin: 0px auto;
    gap: 10px;
    align-items: center;
    justify-content: center;
  
    h1 {
      @include heading(normal, 900, 7px, 26px, $font, #ffffff);
      text-transform: uppercase;
      text-align: center;
    }
  
    &-imgdiv {
      height: 185px;
      width: 120px;

  
      &-imgOne {
        width: 62%;
        position: absolute;
        bottom: 33.2px;
        border-radius: 98px;
      }
      &-imgTwo {
        width: 62%;
        position: absolute;
        bottom: 33.2px;
        border-radius: 98px;
      }
      &-imgThree {
        width: 62%;
        position: absolute;
        bottom: 33.2px;
        border-radius: 98px;
      }
     
     
    }
  }
  li.react-multi-carousel-item.react-multi-carousel-item--active  .slideone {
    transform: scale(1.5);
  }
  
  
  
  li.react-multi-carousel-item.react-multi-carousel-item--active
    
    .slideone-imgdiv {
    border: 4px solid #f7941e;
  }
  
  
  ul.react-multi-carousel-track {
    min-height: 450px;
    align-items: center;
  }

 }