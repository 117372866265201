.herocontainer {
  height: 48rem;
  width: 100%;
  background-image: url("../../assets/images/herobg.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  background-color: #1a1a1a;
  &-wrapper {
    max-width: $fixedwidth;
    padding-top: 12rem;
    margin: 0px auto;
    @include flexbox();
    position: relative;

    &-headings {
      padding-top: 3.5rem;
      width: 100%;
      @include flexbox();
      @include flex-direction(column);
      @include align-items(center);

      h3 {
        @include headingOutline(normal, 900, 28px, 58px, $font, #f7941e);
      }

      h1 {
        @include heading(normal, 900, 60px, 73px, $font, #ffffff);
        text-transform: uppercase;
      }

      p {
        width: 90%;
        @include heading(normal, 300, 16px, 28px, $fontTwo, #ffffff);
        text-align: center;
        text-transform: capitalize;
      }

      &-btndiv {
        padding-top: 2rem;
        width: 20%;
        @include flexbox();
        @include flex-direction(column);
        @include align-items(center);
      }
    }

    &-followus {
      position: absolute;

      @include flexbox();
      @include align-items(center);
      gap: 20px;
      transform: rotate(90deg);
      top: 65%;
      right: -30%;

      &-heading {
        @include flexbox();
        gap: 8px;

        h6 {
          @include heading(normal, 300, 16px, 28px, $fontTwo, #f7941e);
          text-transform: capitalize;
        }
      }

      &-icons {
        @include flexbox();
        @include align-items(center);
        gap: 30px;

        img {
          transform: rotate(-90deg);
          cursor: pointer;
        }
      }
    }
  }
}

@media only screen and (max-width: 2560px) and (min-width: 1920px) {
  .herocontainer {
    &-wrapper {
      &-followus {
        right: -55%;
      }
    }
  }
}

@media only screen and (max-width: 1920px) and (min-width: 1440px) {
  .herocontainer {
    &-wrapper {
      &-followus {
        right: -35%;
      }
    }
  }
}

@media only screen and (max-width: 1440px) and (min-width: 1024px) {
  .herocontainer {
    &-wrapper {
      &-followus {
        right: -20%;
      }
    }
  }
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .herocontainer {
    &-wrapper {
      &-headings {
        padding-top: 3rem;

        h3 {
          font-size: 25px;
        }

        h1 {
          font-size: 38px;
        }

        p {
          width: 70%;
          font-size: 15px;
        }
      }

      &-followus {
        right: -13%;
      }
    }
  }
}
@media only screen and (max-width: 768px) and (min-width: 428px) {
  .herocontainer {
    &-wrapper {
      &-headings {
        padding-top: 0rem;

        h3 {
          font-size: 25px;
        }

        h1 {
          font-size: 38px;
        }

        p {
          width: 70%;
          font-size: 15px;
        }

        &-btndiv {
          width: 30%;

          .btncontainer {
            padding: 15px;

            h5 {
              font-size: 18px;
            }
          }
        }
      }

      &-followus {
        right: -17%;
      }
    }
  }
}
@media only screen and (max-width: 428px) and (min-width: 375px) {
  .herocontainer {
    &-wrapper {
      flex-direction: column;

      &-headings {
        padding: 0rem 15px 15px 15px;

        h3 {
          font-size: 17px;
          line-height: 15px;
        }

        h1 {
          font-size: 24px;
          line-height: 58px;
        }

        p {
          width: 100%;
          font-size: 15px;
        }

        &-btndiv {
          width: 45%;

          .btncontainer {
            padding: 15px;

            h5 {
              font-size: 16px;
            }
          }
        }
      }

      &-followus {
        padding-top: 2rem;
        @include flexbox();
        align-items: center;
        justify-content: center;
        position: static;
        transform: rotate(0deg);

        &-icons {
          img {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 375px) and (min-width: 320px) {
  .herocontainer {
    &-wrapper {
      flex-direction: column;

      &-headings {
        padding: 0rem 15px 15px 15px;

        h3 {
          font-size: 15px;
          line-height: 15px;
        }

        h1 {
          font-size: 23px;
          line-height: 58px;
        }

        p {
          width: 100%;
          font-size: 13px;
        }

        &-btndiv {
          width: 48%;

          .btncontainer {
            padding: 12px;

            h5 {
              font-size: 13px;
            }
          }
        }
      }

      &-followus {
        padding-top: 2rem;
        @include flexbox();
        align-items: center;
        justify-content: center;
        position: static;
        transform: rotate(0deg);

        &-icons {
          gap: 20px;
          img {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 375px) and (min-width: 320px) {
  .herocontainer {
    &-wrapper {
      flex-direction: column;

      &-headings {
        padding: 0rem 15px 15px 15px;

        h3 {
          font-size: 15px;
          line-height: 15px;
        }

        h1 {
          font-size: 23px;
          line-height: 58px;
        }

        p {
          width: 100%;
          font-size: 13px;
        }

        &-btndiv {
          width: 48%;

          .btncontainer {
            padding: 12px;

            h5 {
              font-size: 13px;
            }
          }
        }
      }

      &-followus {
        padding-top: 2rem;
        @include flexbox();
        align-items: center;
        justify-content: center;
        position: static;
        transform: rotate(0deg);

        &-icons {
          gap: 20px;
          img {
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 320px) and (min-width: 280px) {
  .herocontainer {
    &-wrapper {
      padding-top: 11rem;
      flex-direction: column;

      &-headings {
        padding: 0rem 10px 10px 10px;

        h3 {
          font-size: 13px;
          line-height: 15px;
        }

        h1 {
          font-size: 19px;
          line-height: 60px;
        }

        p {
          width: 100%;
          font-size: 12px;
        }

        &-btndiv {
          width: 48%;

          .btncontainer {
            padding: 10px;

            h5 {
              font-size: 12px;
            }
          }
        }
      }

      &-followus {
        padding-top: 2rem;
        @include flexbox();
        align-items: center;
        justify-content: center;
        position: static;
        transform: rotate(0deg);

        &-heading {
          h6 {
            font-size: 14px;
          }
        }

        &-icons {
          gap: 10px;
          img {
            width: 22px;
            height: 22px;
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 280px) and (min-width: 200px) {
  .herocontainer {
    &-wrapper {
      padding-top: 10rem;
      flex-direction: column;

      &-headings {
        padding: 0rem 10px 10px 10px;

        h3 {
          font-size: 11px;
          line-height: 15px;
        }

        h1 {
          font-size: 17px;
          line-height: 60px;
        }

        p {
          width: 100%;
          font-size: 11px;
        }

        &-btndiv {
          width: 48%;

          .btncontainer {
            padding: 10px;

            h5 {
              font-size: 11px;
            }
          }
        }
      }

      &-followus {
        padding: 2rem 10px 10px 10px;
        @include flexbox();
        align-items: center;
        justify-content: center;
        position: static;
        transform: rotate(0deg);

        &-heading {
          h6 {
            font-size: 12px;
          }
        }

        &-icons {
          gap: 8px;
          img {
            width: 20px;
            height: 20px;
            transform: rotate(0deg);
          }
        }
      }
    }
  }
}
