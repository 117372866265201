.faqcontainer {
  width: 100%;
  background-image: url("../../assets/images/footer2.png"), url("../../assets/images/footer1.png");
  background-repeat: no-repeat, no-repeat;
  background-size: 28%, 42% 65%;
  background-position: bottom left, right 28%;
  background-color: #1a1a1a;

  &-wrapper {
    padding-top: 8rem;
    max-width: $fixedwidth;
    margin: 0px auto;
    @include flexbox();
    @include flex-direction(column);

    &-heading {
      @include flexbox();
      @include flex-direction(column);

      h3 {
        @include headingOutline(normal, 900, 28px, 58px, $font, #f7941e);
        letter-spacing: 0.08em;
        text-transform: capitalize;
      }

      h1 {
        @include heading(normal, 900, 50px, 73px, $font, #ffffff);
        text-transform: capitalize;
      }
    }

    &-accord {
      padding-top: 5rem;
      padding-bottom: 8rem;
      border-bottom: 2px solid #ffffff;
      width: 100%;
      @include flexbox();
      @include flex-direction(column);
      gap: 20px;
    }

    &-footer {
      padding-top: 5rem;
      padding-bottom: 5rem;
      @include flexbox();
      @include justify-content(space-between);
      @include align-items(center);

      &-imgdiv {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-copyright {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 8px;

        span {
          @include heading(normal, 300, 12px, 18px, $fontTwo, #ffffff);
          text-transform: capitalize;
        }

        &-heading {
          display: flex;
          align-items: center;
          gap: 5px;

          h2 {
            @include heading(normal, 300, 12px, 18px, $fontTwo, #ffffff);
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }

      &-followus {
        display: flex;
        align-items: center;
        gap: 15px;

        h3 {
          @include heading(normal, 300, 16px, 28px, $fontTwo, #f7941e);
          text-transform: capitalize;
        }
      }

      &-icons {
        @include flexbox();
        @include align-items(center);
        gap: 38px;

        img {
          cursor: pointer;
        }
      }
    }
  }
}
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .faqcontainer {
    background-size: 28%, 58% 63%;

    &-wrapper {
      padding: 8rem 15px 15px 15px;

      &-footer {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
    }
  }
}
@media only screen and (max-width: 768px) and (min-width: 428px) {
  .faqcontainer {
    background-size: 28%, 88% 77%;

    &-wrapper {
      padding: 8rem 15px 15px 15px;

      &-footer {
        padding-top: 2rem;
        padding-bottom: 2rem;

        &-imgdiv {
          width: 20%;

          img {
            width: 100%;
          }
        }

        &-copyright {
          width: 40%;
          span {
            font-size: 11px;
            text-align: center;
          }
          &-heading {
            h2 {
              font-size: 11px;
            }
          }
        }

        &-followus {
          width: 20%;
          gap: 8px;

          h3 {
            font-size: 14px;
          }
        }

        &-icons {
          width: 20%;
          gap: 0px;
          justify-content: space-between;
        }
      }
    }
  }
}
@media only screen and (max-width: 428px) and (min-width: 375px) {
  .faqcontainer {
    background-size: 28%, 140% 70%;

    &-wrapper {
      padding: 8rem 15px 15px 15px;

      &-heading {
        h3 {
          font-size: 22px;
          line-height: 30px;
        }

        h1 {
          font-size: 30px;
        }
      }

      &-accord {
        .accordian-container {
          &-header {
            h1 {
              font-size: 15px;
            }
          }

          &-content {
            p {
              font-size: 13px;
            }
          }
        }
      }

      &-footer {
        padding-top: 1rem;
        padding-bottom: 1rem;
        flex-direction: column;
        gap: 12px;

        &-imgdiv {
          width: 100%;

          img {
            width: 40%;
          }
        }

        &-copyright {
          width: 100%;
          align-items: center;
          justify-content: center;
          display: flex;
        }

        &-followus {
          align-items: center;
          justify-content: center;
          display: flex;
          width: 70%;
          gap: 8px;

          h3 {
            font-size: 14px;
          }
        }

        &-icons {
          width: 35%;
          gap: 0px;
          justify-content: space-between;
        }
      }
    }
  }
}
@media only screen and (max-width: 375px) and (min-width: 320px) {
  .faqcontainer {
    background-size: 28%, 145% 73%;

    &-wrapper {
      padding: 8rem 15px 15px 15px;

      &-heading {
        h3 {
          font-size: 22px;
          line-height: 30px;
        }

        h1 {
          font-size: 28px;
        }
      }

      &-accord {
        .accordian-container {
          &-header {
            h1 {
              font-size: 15px;
            }
          }

          &-content {
            p {
              font-size: 13px;
            }
          }
        }
      }

      &-footer {
        padding-top: 1rem;
        padding-bottom: 1rem;
        flex-direction: column;
        gap: 12px;

        &-imgdiv {
          width: 100%;

          img {
            width: 40%;
          }
        }

        &-copyright {
          width: 100%;
          align-items: center;
          justify-content: center;
          display: flex;
        }

        &-followus {
          align-items: center;
          justify-content: center;
          display: flex;
          width: 70%;
          gap: 8px;

          h3 {
            font-size: 14px;
          }
        }

        &-icons {
          width: 35%;
          gap: 0px;
          justify-content: space-between;
        }
      }
    }
  }
}
@media only screen and (max-width: 320px) and (min-width: 280px) {
  .faqcontainer {
    background-size: 28%, 148% 75%;

    &-wrapper {
      padding: 8rem 15px 15px 15px;

      &-heading {
        h3 {
          font-size: 22px;
          line-height: 30px;
        }

        h1 {
          font-size: 24px;
        }
      }

      &-accord {
        .accordian-container {
          &-header {
            h1 {
              font-size: 15px;
            }
          }

          &-content {
            p {
              font-size: 13px;
            }
          }
        }
      }

      &-footer {
        padding-top: 1rem;
        padding-bottom: 1rem;
        flex-direction: column;
        gap: 12px;

        &-imgdiv {
          width: 100%;

          img {
            width: 40%;
          }
        }

        &-copyright {
          width: 100%;
          align-items: center;
          justify-content: center;
          display: flex;

          span {
            font-size: 10px;
          }
        }

        &-followus {
          align-items: center;
          justify-content: center;
          display: flex;
          width: 70%;
          gap: 8px;

          h3 {
            font-size: 14px;
          }
        }

        &-icons {
          width: 35%;
          gap: 0px;
          justify-content: space-between;
        }
      }
    }
  }
}
